import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import classNames from "classnames"
import quoteStyles from "../components/quote/quote.module.scss"

const NotFoundPage = () => (
  <Layout>
    <Container>
      <SEO title="404: Not found" />

      <h1 className={classNames(quoteStyles.errorpage)}>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
